import { lazy, Suspense } from 'react'

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { CookiesProvider } from 'react-cookie'

import { STAGING_WARNING } from 'config'

import {
  CurrentUserProvider,
  canAccessEditorConsole,
  canAccessPollManager,
  canManagePolls,
  canManageTags,
  isAcceptedTermsOfUse,
} from 'auth'

import { apollo } from 'api'

import {
  SubstituteUserWarning,
  Layout,
  ProtectedRoute,
  SplashScreen,
} from 'common'

import { GetTextProvider } from 'lib/gettext'

import {
  statisticsLoader,
  Layout as LandingLayout,
  GettingStartedLayout,
  GettingStartedAsContributor,
  GettingStartedAsCommenter,
} from 'apps/landing'

import {
  entryTagGroupLoader,
  Layout as BrowseEntriesLayout,
  entriesByPublicationDateLoader,
  EntriesByPublicationDate as BrowseEntriesByPublicationDate,
  entriesByAuthorLoader,
  EntriesByAuthor as BrowseEntriesByAuthor,
  entriesGroupedByRegionLoader,
  EntriesByRegion as BrowseEntriesByRegion,
  entriesByTagsGroupLoader,
  EntriesByTagsGroup as BrowseEntriesByTagsGroup,
  EntrySearch,
  createEntrySearchLoader,
} from 'apps/browse'

import { PollManagerLayout } from 'apps/poll-manager'
import { DashboardNavigate } from 'apps/dashboard'

import { visualizeLoader } from 'apps/visualize/loader'
import teamLoader from 'apps/landing/about/people/team/loader'
import editorsLoader from 'apps/landing/about/people/editors/loader'
import expertsLoader from 'apps/landing/about/people/experts/loader'
import overviewOfPollsLoader from 'apps/landing/polls/overview-of-polls/loader'
import taggingTreesLoader from 'apps/landing/polls/tagging-trees/loader'
import coverageLoader from 'apps/coverage/loader'

import {
  BrowseEntry as BrowseEntryPage,
  loader as browseEntryLoader,
} from 'apps/browse-entry'

import { Questionnaire as QuestionnairePage } from 'apps/questionnaire'
import { Contribute as ContributePage } from 'apps/contribute'
import { Agreement as AgreementPage } from 'apps/agreement'

import {
  EditorConsole,
  SubstituteUserContribute as EditorConsoleSubstituteUserContribute,
} from 'apps/editor-console'

import { PublicProfile, userIdByUsernameLoader } from 'apps/public-profile'

import {
  Layout as SettingsLayout,
  ProfileSettings,
  AccountSettings,
} from 'apps/settings'

import { EntryUpgradePage, entryForUpgradeLoader } from 'apps/entry-upgrade'

import {
  AnalyzePageLayout,
  PlotPageLayout as AnalyzePlotPageLayout,
  ChartSelect as AnalyzeChartSelect,
  SavedQueryList as AnalyzeSavedQueryList,
  SavedQuery as AnalyzeSavedQuery,
  BarChart as AnalyzeBarChart,
  Timeline as AnalyzeTimeline,
  ScatterPlot as AnalyzeScatterPlot,
  QuestionAnswersComparison as AnalyzeQuestionAnswersComparison,
  EntryAnswersComparison as AnalyzeEntryAnswersComparison,
} from 'apps/analyze'

import { MediaLibrary } from 'apps/media-library'

import RootErrorBoundary from './root-error-boundary'
import RouterErrorFallback from './router-error-fallback'

import 'tailwind.css'

const LandingHomePage = lazy(() => import('apps/landing/home/HomePage'))
const AboutLayout = lazy(() => import('apps/landing/about/Layout'))
const AboutTheDRHPage = lazy(() => import('apps/landing/about/about-the-drh'))
const NewsPage = lazy(() => import('apps/landing/about/news/news'))
const ImpactPage = lazy(() => import('apps/landing/about/impact/impact'))
const ResearchPage = lazy(() => import('apps/landing/about/research'))
const PedagogicalUsesPage = lazy(
  () => import('apps/landing/about/pedagogical-uses')
)
const Coverage = lazy(() => import('apps/coverage/coverage'))
const PeopleLayout = lazy(() => import('apps/landing/about/people/layout'))
const TeamPage = lazy(() => import('apps/landing/about/people/team'))
const EditorsPage = lazy(() => import('apps/landing/about/people/editors'))
const ExpertsPage = lazy(() => import('apps/landing/about/people/experts'))
const FundingPage = lazy(() => import('apps/landing/about/funding'))
const PollsLayout = lazy(() => import('apps/landing/polls/Layout'))
const PollPage = lazy(() => import('apps/landing/polls/poll'))
const OverviewOfPollsPage = lazy(
  () => import('apps/landing/polls/overview-of-polls')
)
const TaggingTreesPage = lazy(() => import('apps/landing/polls/tagging-trees'))
const FaqPage = lazy(() => import('apps/landing/faq'))
const GetInvolvedPage = lazy(() => import('apps/landing/get-involved'))
const TermsOfUsePage = lazy(() => import('apps/landing/terms-of-use'))

const SignUpPage = lazy(() => import('apps/sign-up'))
const LoginPage = lazy(() => import('apps/login'))

const VisualizePage = lazy(() => import('apps/visualize'))
const TagManger = lazy(() => import('apps/tag-manager/TagManager'))

const PollManagerPollsPage = lazy(() => import('apps/poll-manager/polls'))
const PollManagerPollPage = lazy(() => import('apps/poll-manager/poll'))
const PollManagerDraftsPage = lazy(() => import('apps/poll-manager/drafts'))

const PollTranslationPage = lazy(
  () => import('apps/poll-manager/poll-translation')
)

const DraftPage = lazy(() => import('apps/poll-manager/draft'))
const DraftHierarchyTab = lazy(
  () => import('apps/poll-manager/draft/hierarchy')
)
const DraftAnswersTab = lazy(() => import('apps/poll-manager/draft/answers'))
const DraftGeneralTab = lazy(() => import('apps/poll-manager/draft/general'))
const DraftPreviewTab = lazy(() => import('apps/poll-manager/draft/preview'))

const Dashboard = lazy(() => import('apps/dashboard'))
// const MediaLibrary = lazy(() => import('apps/media-library'))

const translations = {
  fr: () => import('locales/fr.messages.json').then((value) => value.default),
  'zh-hans': () =>
    import('locales/zh-hans.messages.json').then((value) => value.default),
  'zh-hant': () =>
    import('locales/zh-hant.messages.json').then((value) => value.default),
  ja: () => import('locales/ja.messages.json').then((value) => value.default),
  es: () => import('locales/es.messages.json').then((value) => value.default),
}

function RootRouteComponent() {
  return (
    <>
      <ScrollRestoration />
      <SubstituteUserWarning>
        <Outlet />
      </SubstituteUserWarning>
    </>
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<RouterErrorFallback />}
      element={<RootRouteComponent />}
    >
      <Route element={<LandingLayout />}>
        <Route path="/get-involved" element={<GetInvolvedPage />} />
        <Route path="/getting-started" element={<GettingStartedLayout />}>
          <Route
            path="as-contributor"
            element={<GettingStartedAsContributor />}
          />
          <Route path="as-commenter" element={<GettingStartedAsCommenter />} />

          <Route index element={<Navigate to="as-contributor" replace />} />
        </Route>
      </Route>

      <Route path="/landing">
        <Route index element={<LandingHomePage />} loader={statisticsLoader} />

        <Route element={<LandingLayout />}>
          <Route path="about" element={<AboutLayout />}>
            <Route path="about-the-drh" element={<AboutTheDRHPage />} />
            <Route path="drh-news" element={<NewsPage />} />
            <Route path="impact" element={<ImpactPage />} />
            <Route path="research" element={<ResearchPage />} />
            <Route path="pedagogy" element={<PedagogicalUsesPage />} />
            <Route path="people" element={<PeopleLayout />}>
              <Route index element={<Navigate to="team" replace />} />

              <Route path="team" element={<TeamPage />} loader={teamLoader} />
              <Route
                path="editors"
                element={<EditorsPage />}
                loader={editorsLoader}
              />
              <Route
                path="experts"
                element={<ExpertsPage />}
                loader={expertsLoader}
              />
            </Route>
            <Route path="funding" element={<FundingPage />} />

            <Route index element={<Navigate to="about-the-drh" replace />} />
          </Route>

          <Route path="terms-of-use" element={<TermsOfUsePage />} />

          <Route path="polls" element={<PollsLayout />}>
            <Route path=":pollId" element={<PollPage />} />
            <Route path="overview-of-polls">
              <Route
                index
                element={<OverviewOfPollsPage />}
                loader={overviewOfPollsLoader}
              />
              <Route
                path=":entryTagGroupSlug"
                element={<OverviewOfPollsPage />}
                loader={overviewOfPollsLoader}
              />
            </Route>
            <Route path="tagging-trees">
              <Route index element={<Navigate to="0" replace />} />

              <Route
                path=":tab"
                element={<TaggingTreesPage />}
                loader={taggingTreesLoader}
              />
            </Route>

            <Route
              index
              element={<Navigate to="overview-of-polls" replace />}
            />
          </Route>

          <Route path="faq" element={<FaqPage />} />
        </Route>
      </Route>

      <Route path="/editor-console/su/:targetUserId">
        <Route
          path="contribute"
          element={<EditorConsoleSubstituteUserContribute />}
        />

        <Route
          path="questionnaire/:entryId"
          element={<EditorConsoleSubstituteUserContribute />}
        />
      </Route>

      <Route element={<Layout />}>
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />

        <Route path="/browse">
          <Route element={<BrowseEntriesLayout />} loader={entryTagGroupLoader}>
            <Route
              index
              element={<BrowseEntriesByPublicationDate />}
              loader={entriesByPublicationDateLoader}
            />
            <Route
              path="authors"
              element={<BrowseEntriesByAuthor />}
              loader={entriesByAuthorLoader}
            />
            <Route
              path="regions"
              element={<BrowseEntriesByRegion />}
              loader={entriesGroupedByRegionLoader}
            />
            <Route
              path="tags-group/:groupId"
              element={<BrowseEntriesByTagsGroup />}
              loader={entriesByTagsGroupLoader}
            />
            <Route
              path="search"
              loader={createEntrySearchLoader(20)}
              element={<EntrySearch pageSize={20} />}
            />
          </Route>

          <Route
            path=":entryId"
            element={<BrowseEntryPage />}
            loader={browseEntryLoader}
          />
        </Route>

        <Route
          path="/visualize"
          element={<VisualizePage />}
          loader={visualizeLoader}
        />

        <Route
          path="/visualize/search"
          element={<VisualizePage />}
          loader={visualizeLoader}
        />

        <Route
          path="/visualize/history"
          element={<VisualizePage />}
          loader={visualizeLoader}
        />

        <Route
          path="/visualize/:searchQueryId"
          element={<VisualizePage />}
          loader={visualizeLoader}
        />
        <Route path="coverage" element={<Coverage />} loader={coverageLoader} />
        <Route element={<AnalyzePageLayout />}>
          <Route path="/analyze" element={<AnalyzeChartSelect />} />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/analyze/saved-queries"
              element={<AnalyzeSavedQueryList />}
            />
          </Route>
        </Route>
        <Route element={<AnalyzePlotPageLayout />}>
          <Route path="/analyze/bar-chart" element={<AnalyzeBarChart />} />
          <Route path="/analyze/timeline" element={<AnalyzeTimeline />} />
          <Route
            path="/analyze/scatter-plot"
            element={<AnalyzeScatterPlot />}
          />
          <Route
            path="/analyze/question-answers-comparison"
            element={<AnalyzeQuestionAnswersComparison />}
          />
          <Route
            path="/analyze/entry-answers-comparison"
            element={<AnalyzeEntryAnswersComparison />}
          />
        </Route>
        <Route path="/analyze/:savedQueryId" element={<AnalyzeSavedQuery />} />

        <Route
          path="/accounts/:username"
          element={<PublicProfile />}
          loader={userIdByUsernameLoader}
        />

        <Route
          element={
            <ProtectedRoute
              authorize={(user) => !isAcceptedTermsOfUse(user)}
              fallback={<Navigate to="/getting-started" />}
            />
          }
        >
          <Route path="/agreement" element={<AgreementPage />} />
        </Route>

        <Route
          element={
            <ProtectedRoute
              authorize={isAcceptedTermsOfUse}
              fallback={<Navigate to="/agreement" />}
            />
          }
        >
          <Route path="/settings" element={<SettingsLayout />}>
            <Route path="profile" element={<ProfileSettings />} />
            <Route path="account" element={<AccountSettings />} />

            <Route index element={<Navigate to="profile" replace />} />
          </Route>

          <Route
            path="/accounts/profile"
            element={<Navigate to="/settings" />}
          />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/media-library" element={<MediaLibrary />} />
          <Route path="/contribute" element={<ContributePage />} />

          <Route
            path="/questionnaire/:entryId"
            loader={browseEntryLoader}
            element={<QuestionnairePage />}
          />

          <Route
            path="/upgrade/:entryId"
            element={<EntryUpgradePage />}
            loader={entryForUpgradeLoader}
          />

          <Route
            path="/poll-manager"
            element={<ProtectedRoute authorize={canAccessPollManager} />}
          >
            <Route element={<PollManagerLayout />}>
              <Route path="polls" element={<PollManagerPollsPage />} />
              <Route
                path="drafts"
                element={<ProtectedRoute authorize={canManagePolls} />}
              >
                <Route index element={<PollManagerDraftsPage />} />
              </Route>
            </Route>

            <Route path="polls/:pollId" element={<PollManagerPollPage />} />
            <Route
              path="polls/:pollId/:language"
              element={<PollTranslationPage />}
            />

            <Route
              path="drafts/:draftId"
              element={<ProtectedRoute authorize={canManagePolls} />}
            >
              <Route element={<DraftPage />}>
                <Route path="hierarchy" element={<DraftHierarchyTab />} />
                <Route path="answers" element={<DraftAnswersTab />} />
                <Route path="general" element={<DraftGeneralTab />} />
                <Route path="preview" element={<DraftPreviewTab />} />

                <Route index element={<Navigate to="hierarchy" replace />} />
              </Route>
            </Route>

            <Route index element={<Navigate to="polls" />} />
          </Route>

          <Route
            path="/accounts/editor-console"
            element={<Navigate to="/editor-console" />}
          />

          <Route
            path="/editor-console"
            element={<ProtectedRoute authorize={canAccessEditorConsole} />}
          >
            <Route index element={<EditorConsole />} />
          </Route>

          <Route
            path="/accounts/dashboard"
            element={<Navigate to="/dashboard" />}
          />

          <Route
            path="/tag-manager"
            element={<ProtectedRoute authorize={canManageTags} />}
          >
            <Route index element={<TagManger />} />
          </Route>
        </Route>

        <Route index element={<DashboardNavigate />} />
      </Route>
    </Route>
  )
)

export default function App() {
  return (
    <RootErrorBoundary>
      <CookiesProvider>
        <ApolloProvider client={apollo}>
          <GetTextProvider
            translations={translations}
            sourceLocale="en"
            fallback={<SplashScreen theme="blue" />}
          >
            <CurrentUserProvider fallback={<SplashScreen theme="blue" />}>
              <Suspense fallback={<SplashScreen theme="blue" />}>
                {STAGING_WARNING && (
                  <div className="bg-red-400 py-1 text-center text-sm uppercase text-white">
                    Staging mode – changes won't be saved
                  </div>
                )}
                <RouterProvider router={router} />
              </Suspense>
            </CurrentUserProvider>
          </GetTextProvider>
        </ApolloProvider>
      </CookiesProvider>
    </RootErrorBoundary>
  )
}
